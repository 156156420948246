
<template>
  <div>
    <div
      name="stage"
      class="mt-2"
      :key="'stage.value' + stageIndex"
    >
      {{ 'Этап ' + stage.value }}
    </div>

    <b-form-checkbox-group
      v-if="groupType"
      v-model="stageState.sex"
      name="stage"
      @change="setStageState"
    >
      <b-form-checkbox
        class="mt-1 mr-1"
        :value="'male'"
        :key="'stage.sex.male' + stageIndex"
      > М </b-form-checkbox>
      <b-form-checkbox
        :value="'female'"
        :key="'stage.sex.female' + stageIndex"
      > Ж </b-form-checkbox>
    </b-form-checkbox-group>
  </div>
</template>

<script>
import { BFormInput, BFormCheckbox, BButton, BFormCheckboxGroup } from 'bootstrap-vue'

export default {
  name: 'stage',
  components: {
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormCheckboxGroup
  },
  props: ['stage', 'stageIndex','groupType'],
  data() {
    return {
      stageState: {...this.stage}
    }
  },
  methods: {
    setStageState() {
      this.$emit('setStage', this.stageIndex,  this.stageState)
    }
  }
}
</script>
