<template>
  <div>
    <b-modal
      id="bv-modal-create"
      class="z-index"
      title="Добавить новую дистанцию"
      ok-only
      ok-title="Добавить"
      @ok="addNewSport"
      @hidden="resetModal"
    >
      <div class="con-example-prompt">
        <b-form-input
          v-model="new_kind_sport"
          placeholder="Новая дистанция"
          label="Новая дистанция"
          vs-placeholder="Новая дистанция"
          class="w-full"
        />

        <div class="mt-3 mb-3 title-format">
          Формат
        </div>
        <div
          v-for="(format, index) in sport_formats"
          :key="'format' + index"
          class="mb-1"
        >
          <b-form-radio
            v-model="activeFormat"
            name="sport_format"
            class="mr-6"
            :value="format"
          >
            {{ format }}
          </b-form-radio>
        </div>
      </div>

      <template v-if="activeFormat === 'Лично'">
        <div>
          <distance-block :distance="distance" :mode="'Лично'"></distance-block>
          <div class="mt-3 mb-3 mt-3">
            Группы ({{ groups.length }})
          </div>
          <group-templates @select="(group) => {groups = group}" />
          <template v-for="(group, index) in groups">
            <group 
              :groups="groups"
              :group="group"
              :key="'group' + index"
              :group-index="index"
              @update-group="updateGroup"
              @delete-group="deleteGroup"
            >
            </group>
          </template>
          <b-button
            class="mt-2"
            size="small"
            @click="addGroup"
          >
            Добавить группу
          </b-button>
        </div>
      </template>

      <template v-if="activeFormat === 'Эстафета'">
        <distance-block :distance="distance"></distance-block>
        <div class="mt-3">
          Этапы ({{ distanceStages.length }})
        </div>
        <stage-block :stages="distanceStages"></stage-block>
        <b-button
          class="mt-2"
          size="small"
          @click="addStage"
        >
          Добавить этап
        </b-button>

        <div class="mt-3 mb-3 mt-3">
          Группы ({{ groups.length }})
        </div>
        <group-templates @select="(group) => {groups = group}" />
        <template v-for="(group, index) in groups">
          <group
            :groups="groups"
            :group="group"
            :key="'group' + index"
            :group-index="index"
            @update-group="updateGroup"
            @delete-group="deleteGroup"
          >
          </group>
        </template>
        
        <b-button
          class="mt-2"
          size="small"
          @click="addGroup"
        >
          Добавить группу
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
  
<script>
import {
  BRow, BCol, BFormInput, BFormCheckbox, BButton, BModal, BFormRadio,
} from 'bootstrap-vue'
import GroupTemplates from '@/views/starts/GroupTemplates'
import DistanceBlock from './DistanceBlock.vue'
import Group from './Group.vue'
import StageBlock from './StageBlock.vue'
  
export default {
  name: 'distance',
  components: {
    StageBlock,
    Group,
    DistanceBlock,
    GroupTemplates,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    BFormRadio,
  },
  props: ['age_groups', 'select_kinds_sport'],
  data() {
    return {
      activeFormat: '',
      distance: {
        description: '',
        price: '',
        prices: [],
        slots: 1,
        infinitySlots: false,
        track_link: {},
        track_code: '',
      },
      groups: [],
      distanceStages: [],
      slotDisable: false,
      activeSelectSport: false,
      new_kind_sport: '',
      sport_formats: [
        'Лично',
        'Эстафета',
      ],
    }
  },
  methods: {
    addStage() {
      this.distanceStages.push(
        {
          value: '',
          sex: []
        }
      )
    },
    addGroup() {
      if (this.activeFormat === 'Лично') {
        this.groups.push({
          name: '',
          ageFrom: '',
          ageTo: '',
          sex: ''
        })
      } else if (this.activeFormat === 'Эстафета') {
        this.groups.push({
          name: '',
          ageFrom: '',
          ageTo: '',
          stages: [...this.distanceStages],
        })
      }
    },
    addNewSport() {
      if (this.new_kind_sport) {
        this.select_kinds_sport.push({
          value: this.new_kind_sport,
          prompt: false,
          groups: this.groups,
          format: this.activeFormat,
          distanceStages: this.distanceStages,
          distance: this.distance
        })
      }
      this.groups = []
      this.new_kind_sport = ''
      this.activeFormat = ''
      this.distanceStages = []
      this.distance = {
        description: '',
        price: '',
        prices: [],
        slots: 1,
        infinitySlots: false,
        track_link: {},
        track_code: '',
      }
    },
    updateGroup(group, groupIndex) {
      this.groups[groupIndex] = group
    },
    deleteGroup(indexGroup) {
      this.groups.splice(indexGroup, 1)
    },
    resetModal() {
      this.groups = []
      this.new_kind_sport = ''
      this.activeFormat = ''
      this.distanceStages = []
      this.distance = {
        description: '',
        price: '',
        prices: [],
        slots: 1,
        infinitySlots: false,
        track_link: {},
        track_code: '',
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.add-fille {
  margin: 10px 0;
}
.distance {
  margin-top: 30px;
}
.title-format{
  margin: 15px 0!important;
}
.input__code{
  margin-top: 15px!important;
}
.con-example-prompt{
  input{
    margin-top: 20px!important;
  }
}
.distances__minus{
  margin-left: 5px;
  transition: .3s;
  &:hover{
    cursor: pointer;
    transform: scale(1.1);
  }
}
    .sport-block {
        border-bottom: 1px solid #e5e5e5;
    }

    .z-index {
        z-index: 52011;
    }

.price{
  &__title{
    margin-top: 20px;
  }
  &__items{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 20px -15px;
  }
  &__item{
    padding: 20px;
    border: 1px solid #00000012;
    border-radius: 20px;
    position: relative;
    width: calc(50% - 30px);
    margin: 10px 15px 0 15px;

    &-del{
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

}

</style>
<style>
.flex{
  display: flex;

}
.justify-end{

  justify-content: end;
}
.inline-block{
  display: inline-block;
}
.age{
  display: flex;

}
.age input:last-child{
  margin-left: 30px;
}
.price__item .form-control{
  margin-top: 20px;
}
</style>
