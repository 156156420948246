
<template>
  <div v-if="stages">
    <template v-for="(stage, i) in stages">
      <div :key="'stage_' + i" class="mt-2">Этап {{ i + 1 }}
        <b-button
          title="Удалить"
          variant="flat-danger"
          class="btn-icon inline-block"
          size="sm"
          @click="stages.splice(i,1)"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
      </div>
      <b-form-input
        v-model="stage.value"
        placeholder="Величина"
        vs-placeholder="Величина"
        class="inline-block"
        :key="'stage.value_' + i"
      />
    </template>
  </div>         
</template>

<script>
import { BFormInput, BFormCheckbox, BButton } from 'bootstrap-vue'

export default {
  name: 'stages',
  components: {
    BFormInput,
    BFormCheckbox,
    BButton,
  },
  props: ['stages'],
  data() {
    return {
    }
  }
}
</script>
