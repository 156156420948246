
<template>
  <div>
    <div class="mt-3">
      Группа {{ groupIndex + 1 }}
      <b-button
        title="Удалить"
        variant="flat-danger"
        class="btn-icon inline-block"
        size="sm"
        @click="deleteGroup"
      >
        <feather-icon icon="MinusIcon" />
      </b-button>
    </div>
    <template v-if="groupState">
      <b-form-input
        v-model="groupState.name"
        placeholder="Название"
        class="inline-block"
        :key="'group.name_' + groupIndex"
      />
      
      <div style="margin-top: 20px" :key="'group.age_' + groupIndex">
        Возраст
      </div>
      <div class="age" :key="'group.from_to_' + groupIndex">
        <b-form-input
          v-model="groupState.ageFrom"
          style="margin-top: 10px!important"
          placeholder="От"
          class="inline-block"
        />
        <b-form-input
          v-model="groupState.ageTo"
          style="margin-top: 10px!important"
          placeholder="До"
          class="inline-block"
        />
      </div>
      <div style="margin-top: 20px" :key="'group.groupType' + groupIndex">
        Формат группы
      </div>
      <v-select
        v-if="groupState.stages"
        v-model="groupState.genderGroup"
        label="name"
        :options="sexArrow"
        :reduce="option => option.value"
      />
      <div v-if="groupState.genderGroup === 'mixed'" style="margin-top: 20px" :key="'group.structure' + groupIndex">
        Состав команды
      </div>
      <b-form-input
        v-if="groupState.genderGroup === 'mixed'"
        v-model="groupState.structure"
        style="margin-top: 10px!important"
        placeholder="Состав"
        class="inline-block"
      />
      <div v-if="groupState.stages" :key="'group.stages_' + groupIndex">
        <stage 
           v-for="(stage, index) in groupState.stages"
          :key="'group_' + groupIndex + 'stage' + index"
          :stage="stage"
          :stage-index="index"
          :groupType="groupState.genderGroup === 'mixed'"
          @setStage="setStage"
        >
        </stage>
      </div>
      <div v-else class="inline-block mt-2">
        <div class="inline-block mr-1">
          <b-form-checkbox
            v-model="groupState.sex"
            value="male"
          >М
          </b-form-checkbox>
        </div>
        <div class="inline-block">
          <b-form-checkbox
            v-model="groupState.sex"
            value="female"
          >Ж
          </b-form-checkbox>
        </div>
      </div>
    </template> 
  </div>
</template>

<script>
import { BFormInput, BFormCheckbox, BButton, BFormCheckboxGroup } from 'bootstrap-vue'
import Stage from './Stage.vue'
import vSelect from 'vue-select'

export default {
  name: 'group',
  components: {
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormCheckboxGroup,
    Stage,
    vSelect,
  },
  props: ['group', 'groupIndex'],
  data() {
    return {
      groupState: this.group,
      sexArrow: [{name: 'Мужской', value: 'male'}, {name: 'Женский', value: 'female'}, { name: 'Смешанный', value: 'mixed'}]
    }
  },
  watch: {
    group: {
      handler: function() {
        this.groupState = this.group
      },
      immediate: true,
    },
    groupState: {
      handler(newValue, prev) {
        this.$emit('updateGroup', this.groupState, this.groupIndex)
      },
      deep: true
    },
  },
  methods: {
    setStage(stageIndex, stage) {
      this.groupState.stages[stageIndex] = stage
    },
    deleteGroup() { 
      this.$emit('delete-group', this.groupIndex)
    }
  }
}
</script>
