<script>
import { BButton, BSpinner, BTable } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ChipsParser } from 'sport-parser'
import MemberResultExcelParser from '@/views/starts/chip-sync/MemberResultExcelParser.vue'
import OnlineTranslationOptions from '@/views/starts/chip-sync/OnlineTranslationOptions.vue'

export default {
  name: 'ChipSync',
  components: {
    BTable,
    BButton,
    BSpinner,
    OnlineTranslationOptions,
    MemberResultExcelParser,
  },
  props: {
    runs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      memberData: [],
      syncOptions: {},
      manualCorrection: [],
      currentResult: [],
      interval_id: null,
      syncing: false,
      allRows: {},
      isCorrectionModalVisible: false,
      parser: new ChipsParser(),
      mainParseData: [],
      memberKeysForTable: [
        'name',
        'surname',
        'group',
        'team',
        'number',
        'distance',
        'sex',
        'chip',
      ],
      start: {
        results: [],
      },
    }
  },
  methods: {
    saveExcelData(data) {
      this.memberData = data
    },
    saveSyncOptions(options) {
      this.syncOptions = options
    },
    toggleSync() {
      if (!Array.isArray(this.memberData) || this.memberData.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            icon: 'BellIcon',
            text: 'Не загружен файл с информацией об участниках',
            variant: 'warning',
          },
        })

        return
      }

      if (
        !Array.isArray(Object.keys(this.syncOptions))
        || Object.keys(this.syncOptions).length === 0
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            icon: 'BellIcon',
            text: 'Не заданы опции синхронизации',
            variant: 'warning',
          },
        })

        return
      }

      this.startSync()
        .catch(e => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              text: e.message || 'Неизвестная ошибка',
              variant: 'error',
              icon: 'BellIcon',
            },
          })
        })
    },
    async startSync() {
      this.syncing = !this.syncing
      clearInterval(this.interval_id)

      if (this.syncing) {
        const participantData = this.memberData.map(item => ({
          chip: item.chip,
          start_timestamp: Number(item.start_timestamp),
        }))

        const parameters = {
          points: this.syncOptions.checkpointsSettings.checkpoints.map(
            c => c.point,
          ),
          start: new Date(this.syncOptions.start_date).getTime(),
          minTimes: Object.fromEntries(
            this.syncOptions.checkpointsSettings.settings.map(s => [
              s.point,
              s.time * 1000,
            ]),
          ),
        }

        this.parser.globalReset(participantData, parameters)

        if (!this.interval_id) {
          await this.startParsing()
        }

        this.interval_id = setInterval(
          this.startParsing,
          this.syncOptions.frequency * 1000,
        )
      }
    },
    async startParsing() {
      const fileData = await this.readUrlFile()

      this.mainParseData = fileData
        .map(data => ({
          ind: +data[0],
          point: data[2],
          timestamp: +this.$moment(data[1], 'DD.MM.YYYY HH:mm:ss').format('x'),
        }))
        .filter(item => Boolean(item.point))

      this.currentResult = this.parseResults(
        this.parser.mainParse(this.mainParseData, this.manualCorrection),
      )

      await this.uploadResultsToServer()
    },
    async readUrlFile() {
      const rawFileData = await (await fetch(this.syncOptions.sync_url)).text()
      return rawFileData.split('\n').map(e => e.split(';'))
    },
    parseResults(parsingResult) {
      // TODO: add finish + backup
      return Object.entries(parsingResult).flatMap(([key, value]) => {
        const member = this.memberData.find(m => m.chip.includes(key))

        if (!member) {
          return []
        }

        const res = this.memberKeysForTable.reduce((acc, item) => {
          acc[item] = item !== 'chip' ? member[item] : member[item].join(', ')
          return acc
        }, {})

        const circleEntries = value.map((circle, i) => [
          `circle ${i}`,
          this.$moment.unix(circle.actualValue / 1000).format('HH:mm:ss'),
        ])
        const circleObject = Object.fromEntries(circleEntries)

        return {
          ...res,
          ...circleObject,
        }
      })
    },
    onCellClick(key, chip) {
      if (!key.startsWith('circle')) {
        return
      }

      // TODO: Replace with date picker
      const timestamps = prompt('correction timestamp')

      this.addOrUpdateCorrection(chip, key, timestamps)
    },
    addOrUpdateCorrection(chip, key, timestamps) {
      const correction = this.manualCorrection.find(
        item => item.key === key && item.chip === chip,
      )
      const payload = {
        chip,
        key,
        pointIndex: +key.split(' ')[1],
        correction: +timestamps,
      }

      if (correction) {
        this.manualCorrection[this.manualCorrection.indexOf(correction)] =
          payload
      } else {
        this.manualCorrection.push(payload)
      }
    },
    async uploadResultsToServer() {
      const startId = this.$route.params.id

      const results = this.currentResult.map(res => ({
        name: `${res.name} ${res.surname}`,
        team: res.team,
        sex: res.sex,
        distance: res.distance,
        body_number: res.number,
        start_id: startId,
        circles: JSON.stringify(
          Object.entries(res)
            .filter(([key]) => key.startsWith('circle'))
            .map(([, value]) => value),
        ),
      }))

      await this.$http
        .post('/member-result/bulk', {
          results,
          start_id: startId,
        })
        .then(e => e.data)
        .catch(e => console.log(e))
    },
  },
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Результаты старта</h4>
    </div>

    <div class="card-body">
      <MemberResultExcelParser @onExcelParsed="saveExcelData" />
      <OnlineTranslationOptions @onConfigModified="saveSyncOptions" />

      <b-button
        class="btn-block w-25"
        variant="primary"
        @click="toggleSync"
      >
        <b-spinner
          v-if="syncing"
          small
        />
        {{ syncing ? 'Остановить синхронизацию' : 'Запустить синхронизацию' }}
      </b-button>

      <b-table
        v-if="currentResult.length"
        class="mt-3"
        :items="currentResult"
        striped
        responsive
      >
        <template #cell()="data">
          <div @click="onCellClick(data.field.key, data.item.chip)">
            {{ data.value }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
