<template>
  <div>
    <b-modal
      :id="'bv-modal-combo'"
      class="z-index"
      title="Создать комбо из дистанций"
      ok-only
      ok-title="Cоздать"
      @ok="addComboDistances"
    >
      <b-form-input
        v-model="comboName"
        placeholder="Название"
        label="Название"
        vs-placeholder="Название"
        class="w-full"
      />

      <b-form-input
        v-model="sale"
        placeholder="Скидка"
        vs-placeholder="Скидка"
        class="inline-block mt-2"
      />

      <b-form-checkbox-group 
        v-for="(distance, index) in distances" 
        :key="index"
        v-model="selectedDistances"
      >
        <b-form-checkbox
          class="mt-1 mr-1"
          :value="distance.value"
          :key="index"
        > 
          {{ distance.value }}
        </b-form-checkbox>
      </b-form-checkbox-group>

      <div class="price mt-2">
        <h4 class="price__title">
          Доп. цены
        </h4>
        <div class="price__items mt-2">
          <div
            v-for="(item, i) in prices"
            :key="'price_' + i"
            class="price__item"
          >
            <div
              class="price__item-del"
            >
              <svg
                width="10"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                style="cursor:pointer"
                @click="prices.splice(i,1)"
              ><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" /></svg>
            </div>
            <flat-pickr
              v-model="item.c_from"
              placeholder="От"
              class="form-control"
              :config="config"
            />
            <flat-pickr
              v-model="item.c_to"
              placeholder="До"
              class="form-control"
              :config="config"
            />
            <b-form-input
              v-model.number="item.value"
              type="number"
              placeholder="Цена"
            />
          </div>
        </div>
        <b-button
          type="submit"
          variant="primary"
          class="mr-1"
          @click="addPrices()"
        >
          Добавить цену
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormCheckbox, BButton, BModal, BFormRadio, BFormCheckboxGroup
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import FileUploader from '../../../global-components/FileUploader';
import { Russian } from 'flatpickr/dist/l10n/ru.js'
  
export default {
  name: 'distance-modal-combo',
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    BFormRadio,
    BFormCheckboxGroup,
    flatPickr,
    FileUploader,
  },
  props: ['select_kinds_sport', ],
  data() {
    return {
      selectedDistances: [],
      comboName: '',
      sale: null,
      prices: [],
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
    }
  },
  watch: {
  },
  computed: {
    distances() {
      return this.select_kinds_sport.filter(item => item?.type !== 'combo')
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    addComboDistances() {
      const distancesData = []
      this.selectedDistances.forEach(item => {
        this.select_kinds_sport.forEach(distance => {
          if (item === distance.value){
            distancesData.push(distance)
          }
          return
        })
      });

      this.select_kinds_sport.push({
        value: this.comboName,
        type: "combo",
        distances: distancesData,
        sale: this.sale,
        prices: this.prices
      })
    },
    addPrices() {
      this.prices.push({ c_to: '', c_from: '', value: '' })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
