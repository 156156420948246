<template>
  <div>
    <div
      style="width: 100%; position:relative; padding: 20px"
      class="p-6"
    >
      <div 
        v-for="(sport, index) in select_kinds_sport"
        :key="'format' + index"
        class="sport-block"
      >
        <div
          :key="'form_' + index"
          class="flex items-center mt-2 mb-2"
        >
          <span>
            <b-button
              title="Удалить"
              variant="flat-danger"
              class="btn-icon inline-block"
              size="sm"
              @click="deleteSport(index)"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
            <strong>{{ sport.value }}</strong>
            <b-button
              variant="flat-warning"
              class="btn-icon inline-block"
              size="sm"
              @click="$bvModal.show('bv-modal-edit' + index)"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </span>
        </div>
        <distance-modal-edit
          :sport="sport"
          :index="index"
          :age_groups="age_groups"
          :select_kinds_sport="select_kinds_sport"
        />
      </div>
      <distance-modal-create
        :age_groups="age_groups"
        :select_kinds_sport="select_kinds_sport"
      />
      <distance-modal-combo
        :select_kinds_sport="select_kinds_sport"
      />
      <b-button
        class="mt-3"
        variant="primary"
        @click="$bvModal.show('bv-modal-create')"
      >
        Добавить дистанцию
      </b-button>

      <b-button
        class="mt-3 ml-3"
        variant="primary"
        @click="$bvModal.show('bv-modal-combo')"
      >
        Добавить комбо
      </b-button>
    </div>
  </div>
</template>

<script>
import StartDays from "@/views/starts/StartDays";
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormRadio,
  BModal,
  BRow
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import DistanceModalCreate from "@/views/starts/new-version/DistanceModalCreate";
import DistanceModalEdit from "@/views/starts/new-version/DistanceModalEdit";
import DistanceModalCombo from "@/views/starts/new-version/DistanceModalCombo.vue";

export default {
  name: 'MainForm',
  components: {
    StartDays,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    flatPickr,
    BFormRadio,
    DistanceModalCreate,
    DistanceModalEdit,
    DistanceModalCombo
  },
  props: ['start', 'select_kinds_sport', 'age_groups'],
  data() {
    return {
    }
  },
  watch: {
    select_kinds_sport() {
      console.log('select_kinds_sport', JSON.parse(JSON.stringify(this.select_kinds_sport)));
    }
  },
  methods: {
    deleteSport(i) {
      this.select_kinds_sport.splice(i, 1)
    },
  }
}
</script>

<style lang="scss" scoped>
.day-button{
  color: #ffffff;
  border-radius: 5px;
}
</style>