<template>
  <div v-if="member">
    <div class="input">
      <span>
        ID
      </span>
      <b-form-input
        v-model="member.id"
        placeholder="ID"
      />
    </div>
    <div class="input">
      <span>
        Name
      </span>
      <b-form-input
        v-model="member.name"
        placeholder="Имя"
      />
    </div><div class="input">
      <span>
        Фамилия
      </span>
      <b-form-input
        v-model="member.surname"
        placeholder="Фамилия"
      />
    </div>
    <div class="input">
      <span>
        Пол
      </span>
      <b-form-input
        v-model="member.gender"
        placeholder="Пол"
      />
    </div><div class="input">
      <span>
        Email
      </span>
      <b-form-input
        v-model="member.email"
        placeholder="Email"
      />
    </div><div class="input">
      <span>
        VK
      </span>
      <b-form-input
        v-model="member.vk"
        placeholder="VK"
      />
    </div><div class="input">
      <span>
        Instagram
      </span>
      <b-form-input
        v-model="member.instagram"
        placeholder="Instagram"
      />
    </div>
    <div class="input">
      <span>
        Whatsapp
      </span>
      <b-form-input
        v-model="member.whatsapp"
        placeholder="Whatsapp"
      />
    </div>
    <div class="input">
      <span>
        Telegram
      </span>
      <b-form-input
        v-model="member.telegram"
        placeholder="Telegram"
      />
    </div>
    <div class="input">
      <span>
        Дистанция
      </span>
      <b-form-input
        v-model="member.distance"
        placeholder="Дистанция"
      />
    </div>
    <div class="input">
      <span>
        Телефон
      </span>
      <b-form-input
        v-model="member.phone"
        placeholder="Телефон"
      />
    </div>
    <div class="input">
      <span>
        Команда
      </span>
      <b-form-input
        v-model="member.team"
        placeholder="Команда"
      />
    </div>

    <div class="input">
      <span>
        Дата рождения
      </span>
      <flat-pickr

        v-model="member.birthday"
        class="form-control"
        :config="config"
      />

    </div> <div class="input">
      <span>
        Статус
      </span>
      <b-form-input
        v-model="member.payment"
        placeholder="Статус"
      />
    </div>
    <div class="input">
      <span>
        Сбербанк ID
      </span>
      <b-form-input
        v-model="member.sberbank_id"
        placeholder="Сбербанк ID"
      />
    </div>
    <div class="input">
      <span>
        Группа
      </span>
      <b-form-input
        v-model="member.group"
        placeholder="Группа"
      />
    </div>
    <div class="input">
      <span>
        Цена
      </span>
      <b-form-input
        v-model="member.price"
        placeholder="Имя"
      />
    </div>
    <b-button
      variant="primary"
      class="mr-1"
      @click="saveMember"
    >
      Сохранить

    </b-button>

  </div>

</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup, BButton, VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import crud_module from '@/libs/crud_module'

const member_module = crud_module({
  entity_name: 'member-start',
})

export default {
  name: 'EditMember',
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    flatPickr,
    BButton,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: ['id', 'modalRef'],
  data() {
    return {
      member: null,
      config: {
        allowInput: true,
        altFormat: 'd.m.Y',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
    }
  },
  async created() {
    console.log(this.id)
    this.member = await member_module.fetchItem(this.id).then(r => r.data)
  },

  methods: {
    async saveMember() {
      const result = await member_module.updateItem(this.member.id, this.member).then(r => r.data)
      if (result.success) {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Старт сохранен',
            variant: 'success',
          },
        })
        this.modalRef.hide()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Ошибка сохранения',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.input{
  margin-bottom: 15px;
  span{
    padding-bottom: 8px;
    display: block;
  }
}
</style>
