<template>
  <div>
    <div class="nav-bar">
      <div
        class="nav-bar__item"
        :class="{ active: step === 1 }"
        @click="step = 1"
      >
        Общее
      </div>
      <div
        class="nav-bar__item"
        :class="{ active: step === 2 }"
        @click="step = 2"
      >
        Результаты
      </div>
      <div
        class="nav-bar__item"
        :class="{ active: step === 3 }"
        @click="step = 3"
      >
        Участники
      </div>
      <div
        class="nav-bar__item"
        :class="{ active: step === 4 }"
        @click="step = 4"
      >
        Результаты новые
      </div>
    </div>
    <div v-if="step === 4">
      <ChipSync />
    </div>

    <div v-if="step === 1">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Общая информация
          </h4>
        </div>
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group label="Название старта">
                  <b-form-input
                    v-model="start.name"
                    placeholder="Название старта"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Краткое название">
                  <b-form-input
                    v-model="start.short_name"
                    placeholder="Краткое название"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Серийный номер">
                  <b-form-input
                    v-model="start.serial_number"
                    placeholder="Серийный номер"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                class="switches"
              >
                <div class="switch">
                  <b-card-text class="mb-0">
                    Выводить на главную?
                  </b-card-text>
                  <b-form-checkbox
                    v-model="start.on_main_page"
                    checked="true"
                    class="custom-control-primary"
                    name="check-view"
                    switch
                  />
                </div>
                <div class="switch">
                  <b-card-text class="mb-0">
                    Регистрация на нашем сайте?
                  </b-card-text>
                  <b-form-checkbox
                    v-model="start.reg_on_site"
                    checked="true"
                    class="custom-control-primary"
                    name="check-reg"
                    switch
                  />
                </div>
                <div class="switch">
                  <b-card-text class="mb-0">
                    Отключить оплату?
                  </b-card-text>
                  <b-form-checkbox
                    v-model="start.payment_disabled"
                    checked="true"
                    class="custom-control-primary"
                    name="check-view"
                    switch
                  />
                </div>
                <div
                  v-if="start.payment_disabled"
                  class="inline-block"
                >
                  <b-form-group label="Способ оплаты">
                    <b-form-input
                      v-model="start.payment_type"
                      placeholder="Способ оплаты"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="6">
                <b-form-group label="Координаты места проведения">
                  <b-form-input
                    v-model="start.coordinates"
                    placeholder="Координаты места проведения"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="!start.reg_on_site"
                md="6"
              >
                <b-form-group label="Ссылка на сайт регистрации">
                  <b-form-input
                    v-model="start.reg_link"
                    placeholder="Ссылка"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="URL">
                  <b-form-input
                    v-model="start.slug"
                    placeholder="URL"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Город проведения" />
                <v-select
                  v-model="start.city_id"
                  taggable
                  push-tags
                  :options="cities"
                  label="name"
                  :reduce="option => option.id"
                />
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Данные интеграции с copernico
          </h4>
        </div>
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group label="ID старта">
                  <b-form-input
                    v-model="start.copernico_id"
                    placeholder="ID старта"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Название preset">
                  <b-form-input
                    v-model="start.copernico_preset"
                    placeholder="Название preset"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Форма старта
          </h4>
        </div>
        <div class="card-body">
          <b-form-group class="time-pick">
            <span>Считать возраст участников на дату:</span>
            <flat-pickr
              v-model="age_counting"
              class="form-control"
              :config="config"
            />
          </b-form-group>
        </div>
        <div
          v-if="Array.isArray(select_kinds_sport[0])"
          class="ml-2"
        >
          <start-days
            v-for="(day, index) in select_kinds_sport"
            v-show="index === active_day"
            :key="'sport_day_' + index"
            :age_groups="age_groups"
            :select_kinds_sport="day"
          />
        </div>

        <main-form
          v-else
          :select_kinds_sport="select_kinds_sport"
          :start="start"
          :age_groups="age_groups"
        />
      </div>

      <div class="card">
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <div class="upload-wrap">
                  <file-uploader
                    v-model="start.afisha_link"
                    :default="start.afishaLinkFile"
                    title="Афиша старта"
                  />
                </div>
              </b-col>
              <b-col md="6">
                <div class="upload-wrap">
                  <file-uploader
                    v-model="start.poster_link"
                    :default="start.posterLinkFile"
                    title="Обложка старта"
                  />
                </div>
              </b-col>
              <b-col md="6">
                <div class="upload-wrap">
                  <file-uploader
                    v-model="start.condition_file"
                    :default="start.conditionFile"
                    title="Положение старта"
                  />
                </div>
              </b-col>
              <b-col md="6">
                <div class="upload-wrap">
                  <file-uploader
                    v-model="start.small_image"
                    :default="start.smallImageFile"
                    title="Доп. изображение"
                  />
                </div>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group label="ID старта">
                  <b-form-input
                    v-model="start.copernico_id"
                    placeholder="ID старта"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group label="Полезные ссылки">
                  <div
                    v-for="(link, index) in useful_links"
                    :key="'links' + index"
                    class="links"
                  >
                    <svg
                      width="16"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="minus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="svg-inline--fa fa-minus fa-w-14"
                      @click="useful_links.splice(index, 1)"
                    >
                      <path
                        fill="currentColor"
                        d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                        class=""
                      />
                    </svg>
                    <b-form-input
                      v-model="link.text"
                      class="links__input"
                      placeholder="Название"
                    />
                    <b-form-input
                      v-model="link.url"
                      class="links__input"
                      placeholder="Адрес"
                    />
                  </div>
                  <b-button
                    variant="primary"
                    class="mr-1"
                    @click="useful_links.push({ text: '', url: '' })"
                  >
                    Добавить ссылку
                  </b-button>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Социальные сети">
                  <div
                    v-for="(social, index) in socials"
                    :key="'social' + index"
                    class="links"
                  >
                    <svg
                      width="16"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="minus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="svg-inline--fa fa-minus fa-w-14"
                      @click="socials.splice(index, 1)"
                    >
                      <path
                        fill="currentColor"
                        d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                        class=""
                      />
                    </svg>
                    <b-form-input
                      v-model="social.url"
                      class="links__input"
                    />
                    <b-form-input
                      v-model="social.code"
                      class="links__input"
                    />
                  </div>

                  <b-button
                    variant="primary"
                    class="mr-1"
                    @click="socials.push({ code: '', url: '' })"
                  >
                    Добавить ссылку
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="12">
                <b-form-group
                  v-for="(organizer, index) in organizers"
                  :key="'organizer' + index"
                  :label="'Организатор ' + (index + 1)"
                >
                  <div class="organizer">
                    <div class="organizer__wrap">
                      <svg
                        width="16"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="minus"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        class="svg-inline--fa fa-minus fa-w-14"
                        @click="organizers.splice(index, 1)"
                      >
                        <path
                          fill="currentColor"
                          d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                          class=""
                        />
                      </svg>
                      <b-form-input
                        v-model="organizer.name"
                        class="organizer__input"
                        placeholder="Имя организатора"
                      />
                      <b-form-input
                        v-model="organizer.phone"
                        class="organizer__input"
                        placeholder="Телефон"
                      />
                      <div class="organizer__switch">
                        <b-card-text class="mb-0">
                          WhatsApp
                        </b-card-text>
                        <b-form-checkbox
                          v-model="organizer.messengers"
                          checked="true"
                          class="custom-control-primary"
                          name="check-whats"
                          value="WA"
                          switch
                        />
                      </div>
                      <div class="organizer__switch">
                        <b-card-text class="mb-0">
                          Telegram
                        </b-card-text>
                        <b-form-checkbox
                          v-model="organizer.messengers"
                          checked="true"
                          class="custom-control-primary"
                          name="check-tele"
                          value="TG"
                          switch
                        />
                      </div>
                      <div class="organizer__switch">
                        <b-card-text class="mb-0">
                          Viber
                        </b-card-text>
                        <b-form-checkbox
                          v-model="organizer.messengers"
                          checked="true"
                          class="custom-control-primary"
                          name="check-vib"
                          value="VB"
                          switch
                        />
                      </div>
                    </div>

                    <div>
                      Соц. сети
                      <div
                        v-for="(link, index) in organizer.social_networks"
                        :key="'organizer-social' + index"
                        class="links"
                      >
                        <svg
                          width="16"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="minus"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          class="svg-inline--fa fa-minus fa-w-14"
                          @click="organizer.social_networks.splice(index, 1)"
                        >
                          <path
                            fill="currentColor"
                            d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                            class=""
                          />
                        </svg>
                        <b-form-input
                          v-model="link.url"
                          class="links__input"
                          placeholder="Ссылка на соц. сеть"
                        />
                        <b-form-input
                          v-model="link.code"
                          class="links__input"
                          placeholder="Код соц. сети"
                        />

                        <svg
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-popover.html.hover.bottom="
                            'Instagram - \ninstagram\n <br> Telegram - \ntelegram\n <br> Vkontakte - \nvk\n <br> <a href=\'https://fontawesome.com/v4.7/icons/\'>Просмотреть все коды</a>'
                          "
                          variant="outline-primary"
                          width="20"
                          class="ml-1"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="question"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <b-button
                    variant="primary"
                    class="mr-1"
                    @click="
                      organizer.social_networks.push({ code: '', url: '' })
                    "
                  >
                    <b-spinner
                      v-if="isloading"
                      small
                    />
                    Добавить соц. сеть
                  </b-button>
                </b-form-group>

                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="
                    organizers.push({
                      name: '',
                      phone: '',
                      messengers: [],
                      social_networks: [
                        {
                          code: '',
                          name: '',
                        },
                      ],
                    })
                  "
                >
                  Добавить организатора
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <h4 class="card-title">
            Описание
          </h4>
          <div class="my-1">
            Полное описание
          </div>
          <quill-editor v-model="start.description" />
          <div class="my-1 mt-5">
            Краткое описание
          </div>
          <quill-editor v-model="start.condition_short" />
        </div>
      </div>
      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <b-form-group label="Спонсоры">
            <div
              v-for="(sponsor, index) in sponsors"
              :key="'sponsor' + index"
            >
              <div class="links">
                <b-col md="6">
                  <div class="flex-wrapper">
                    <svg
                      width="16"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="minus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="svg-inline--fa fa-minus fa-w-14"
                      @click="sponsors.splice(index, 1)"
                    >
                      <path
                        fill="currentColor"
                        d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                        class=""
                      />
                    </svg>
                    <b-form-input
                      v-model="sponsor.name"
                      placeholder="Название"
                      class="links__input"
                    />
                  </div>
                </b-col>

                <b-col md="6">
                  <b-form-input
                    v-model="sponsor.site"
                    placeholder="Сайт"
                    class="links__input"
                  />
                </b-col>
              </div>
              <div>
                <b-col
                  style="margin: 20px 0"
                  md="6"
                >
                  <file-uploader
                    v-model="sponsor.logo"
                    title="Логотип"
                  />
                </b-col>
              </div>
            </div>

            <b-button
              variant="primary"
              class="mr-1"
              @click="sponsors.push({ name: '', site: '', logo: {} })"
            >
              Добавить спонсора
            </b-button>
          </b-form-group>
        </div>
      </div>
      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <b-form-group label="Схема проезда">
            <b-form-input class="links__input" />

            <div class="switch">
              <b-card-text class="mb-0">
                Сервис поиска попутчиков
              </b-card-text>
              <b-form-checkbox
                checked="true"
                class="custom-control-primary"
                name="check-view"
                switch
              />
            </div>
          </b-form-group>
        </div>
      </div>

      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="12">
                <b-form-group label="Программа старта" />
              </b-col>

              <b-col md="6">
                <div class="links">
                  <b-form-group class="time-pick">
                    <span>Начало регистрации</span>
                    <flat-pickr
                      v-model="start.registration_start_date"
                      class="form-control"
                      :config="config"
                    />
                  </b-form-group>

                  <b-form-group class="time-pick">
                    <span>Конец регистрации</span>
                    <flat-pickr
                      v-model="start.registration_end_date"
                      class="form-control"
                      :config="config"
                    />
                  </b-form-group>
                </div>
                <b-col
                  md="12"
                  style="padding: 0"
                >
                  <div class="links">
                    <b-form-group class="time-pick">
                      <span>Начало старта</span>
                      <flat-pickr
                        v-model="start.start_date"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>

                    <b-form-group class="time-pick">
                      <span>Конец старта</span>
                      <flat-pickr
                        v-model="start.end_date"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-form-group>
                  <b-button
                    variant="primary"
                    class="mr-1"
                    @click="socials.push({ code: '', url: '' })"
                  >
                    Добавить событие
                  </b-button>
                </b-form-group>
              </b-col>

              <b-form-group>
                <b-col
                  md="12"
                  class="switches"
                >
                  <div class="switch">
                    <b-card-text class="mb-0">
                      Закрыть регистрацию на старт
                    </b-card-text>
                    <b-form-checkbox
                      v-model="start.isClosed"
                      checked="true"
                      class="custom-control-primary"
                      name="check-view"
                      switch
                    />
                  </div>
                  <div class="switch">
                    <b-card-text class="mb-0">
                      Дата старта изменена
                    </b-card-text>
                    <b-form-checkbox
                      v-model="start.isRescheduled"
                      checked="true"
                      class="custom-control-primary"
                      name="check-reg"
                      switch
                    />
                  </div>
                  <div class="switch">
                    <b-card-text class="mb-0">
                      Регистрация на месте
                    </b-card-text>
                    <b-form-checkbox
                      checked="true"
                      class="custom-control-primary"
                      name="check-reg"
                      switch
                    />
                  </div>
                </b-col>
              </b-form-group>
            </b-row>
          </b-form>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Промокоды
          </h4>
        </div>
        <div class="card-body">
          <div class="promo__items">
            <div
              v-for="(item, i) in promocodes"
              :key="'promo_' + i"
              class="promo__item"
            >
              <div
                class="promo__item-del"
                @click="promocodes.splice(i, 1)"
              >
                <svg
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                  />
                </svg>
              </div>

              <b-form-input
                v-model.number="item.code"
                placeholder="Код"
                style="margin-bottom: 15px"
                class="inline-block"
              />
              <b-form-input
                v-model.number="item.value"
                placeholder="Скидка"
                class="inline-block"
              />
            </div>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-3
            type="submit"
            variant="primary"
            class="mr-1"
            @click="promocodes.push({ code: null, value: null })"
          >
            Добавить промокод
          </b-button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Скидки по возрасту
          </h4>
        </div>
        <div class="card-body">
          <div class="discount__items">
            <div
              v-for="(item, i) in discount"
              :key="'discount_' + i"
              class="discount__item"
            >
              <div
                class="discount__item-del"
                @click="discount.splice(i, 1)"
              >
                <svg
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                  />
                </svg>
              </div>
              <b-form-input
                v-model.number="item.c_to"
                type="number"
                placeholder="От"
                style="margin-bottom: 10px"
              />
              <b-form-input
                v-model.number="item.c_from"
                type="number"
                placeholder="До"
                style="margin-bottom: 10px"
              />
              <div class="switch">
                <b-card-text class="mb-0">
                  Скидка в %
                </b-card-text>
                <b-form-checkbox
                  v-model="item.percent"
                  class="custom-control-primary"
                  name="check-reg"
                  switch
                />
              </div>
              <b-form-input
                v-model.number="item.value"
                type="number"
                :placeholder=" item.percent ? '% скидки' : 'скидка(руб)'"
              />
            </div>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="
              discount.push({
                c_to: null,
                c_from: null,
                value: null,
                percent: false,
              })
            "
          >
            Добавить скидку
          </b-button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Фильтрация
          </h4>
        </div>
        <div class="card-body">
          <b-row>
            <b-col md="6">
              <b-form-group label="Виды спорта" />
              <v-select
                v-model="kindOfSports_local"
                multiple
                :reduce="e => e.id"
                label="name"
                :options="kindOfSports"
              />
            </b-col>
            <b-col md="6">
              <b-form-group label="Сезон" />
              <b-form-select
                v-model="start.season_id"
                :options="seasons"
                text-field="name"
                value-field="id"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Банковская статистика
          </h4>
        </div>
        <div class="card-body">
          <start-statistic :start_id="+$route.params.id" />
        </div>
      </div>
    </div>
    <div v-if="step === 2">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Результаты старта
          </h4>
        </div>
        <div class="card-body">
          <div
            v-for="(result, index) in start.results"
            :key="'result-link' + index"
          >
            <file-uploader
              v-model="result.fileID"
              :default="result.file"
              :title="'Файл ' + (index + 1)"
            />
            <b-form-input
              v-model="result.name"
              class="links__input"
              placeholder="Название файла"
            />
          </div>
          <b-button
            variant="primary"
            class="mr-1"
            @click="addResultLink"
          >Добавить результат</b-button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Загрузка результатов Excel
          </h4>
        </div>
        <div class="card-body">
          <div
            v-for="(column, ind) in excelResultColumns"
            :key="'excel_column_' + ind"
          >
            <b-form-group :label="'Circle' + (ind + 1)">
              <b-form-input
                v-model="column.value"
                class="links__input"
                placeholder="Название колонки"
              />
              <b-button
                variant="primary"
                size="sm"
                class="mb-1"
                @click="excelResultColumns.splice(ind, 1)"
              >Удалить</b-button>
            </b-form-group>
          </div>
          <b-button
            variant="primary"
            class="mb-5"
            @click="excelResultColumns.push({ value: '' })"
          >Добавить название колонки</b-button>

          <b-form-group label="Забеги">
            <div
              v-for="(run, index) in runs"
              :key="'run' + index"
            >
              <div class="links">
                <b-col md="6">
                  <div class="flex-wrapper">
                    <svg
                      width="16"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="minus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="svg-inline--fa fa-minus fa-w-14"
                      @click="deleteRun(index)"
                    >
                      <path
                        fill="currentColor"
                        d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                        class=""
                      />
                    </svg>
                    <b-form-input
                      v-model="run.name"
                      placeholder="Название"
                      class="links__input"
                    />
                  </div>
                </b-col>

                <b-col md="6">
                  <flat-pickr
                    v-model="run.time"
                    placeholder="Дата"
                    class="form-control"
                    :config="config"
                  />
                </b-col>
              </div>
            </div>

            <b-button
              variant="primary"
              class="mr-1"
              @click="addRun()"
            >
              Добавить забег
            </b-button>
          </b-form-group>

          <excel-uploader
            :on-success="successResultUpload"
            :runs="runs"
          />
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Результаты из файла
          </h4>
        </div>
        <div class="card-body">
          <start-members-results
            ref="fileResultsComponent"
            :start_id="$route.params.id"
            :columns="excelResultColumns"
          />
        </div>
      </div>
    </div>
    <div v-if="step === 3">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Зарегистрированные участники
          </h4>
        </div>
        <div class="card-body">
          <div>
            <b-modal
              id="modal-5"
              ref="delete-modal"
              title="Удалить участника?"
              :hide-footer="true"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="deleteMember(currentMemberId)"
              >
                Удалить
              </b-button>
            </b-modal>

            <b-modal
              id="modal-1"
              ref="edit-modal"
              title="Редактирование"
              :hide-footer="true"
            >
              <edit-member
                :id="currentMemberId"
                :modal-ref="$refs['edit-modal']"
                @save="getMembersFromStart"
              />
            </b-modal>
            <b-form-group class="search-wrap">
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="searchMember"
                  placeholder="Поиск"
                  type="search"
                  class="d-inline-block"
                  @update="
                    (search, loading) => getFilteredMembers(search, loading)
                  "
                />
              </div>
            </b-form-group>
            <b-table
              :items="start.members"
              :fields="fields"
              striped
              responsive
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template #cell(createdAt)="data">
                {{ $moment(data.item.createdAt).format('DD.MM.YYYY') }}
              </template>
              <template #cell(id)="data">
                <div style="display: flex">
                  <b-form-checkbox
                    v-model="selectedMembers"
                    :value="data.item.id"
                  />
                  <span
                    v-b-modal.modal-1
                    @click="editMember(data.item.id)"
                  ><svg
                    data-v-414f8174=""
                    xmlns="http://www.w3.org/2000/svg"
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="mr-50 feather feather-edit-2"
                  >
                    <path
                      data-v-414f8174=""
                      d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                    /></svg></span>
                  <div
                    v-b-modal.modal-5
                    @click="deleteMemberModal(data.item.id)"
                  >
                    <svg
                      style="margin-right: 10px"
                      data-v-3f2fc030=""
                      fill="#7367f0"
                      width="14"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        data-v-3f2fc030=""
                        d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"
                      />
                    </svg>
                  </div>
                  {{ data.item.id }}
                </div>
              </template>
              <template #cell(group)="data">
                <span v-if="data.item.group" style="font-size: 12px;"
                >{{JSON.parse(data.item.group).name }} {{ JSON.parse(data.item.group).ageFrom }} -
                  {{ JSON.parse(data.item.group).ageTo }}</span
                >
              </template>
              <template #cell(payment)="data">
                <b-badge
                  v-if="data.item.payment === 1"
                  variant="success"
                >
                  Оплачено
                </b-badge>
                <b-badge
                  v-else-if="data.item.payment === 2"
                  variant="warning"
                >
                  Беплатно
                </b-badge>
                <b-badge
                  v-else
                  variant="secondary"
                >
                  Не оплачено
                </b-badge>
                <b-button
                  v-if="!data.item.payment"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success"
                  class="btn-icon ml-1"
                  @click="refreshPaymentLink(data.item)"
                >
                  <b-spinner
                    v-if="data.item.disabled"
                    small
                  />
                  <feather-icon
                    v-else
                    icon="DollarSignIcon"
                  />
                </b-button>
              </template>
            </b-table>
            <b-button
              variant="primary"
              class="mr-1"
              :disabled="downloadDisabled"
              @click="downloadExcel"
            >
              <b-spinner
                v-if="downloadDisabled"
                small
              />
              {{ downloadDisabled ? 'Скачивание...' : 'Скачать Excel' }}
            </b-button>
            <b-button
              variant="primary"
              class="mr-1"
              @click="changeStatus(1)"
            >
              Отметить оплаченными
            </b-button>
            <b-button
              variant="primary"
              class="mr-1"
              @click="changeStatus(2)"
            >
              Отметить бесплатными
            </b-button>
            <b-button
              variant="primary"
              class="mr-1"
              @click="cancelPayments"
            >
              Отменить оплату
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        @click="saveItem"
      >
        {{ 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BTable,
  VBPopover,
  BFormSelect,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import vSelect from 'vue-select'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import xlsx from 'json-as-xlsx'
import FileUploader from '@/global-components/FileUploader'
import StartDays from './StartDays'
import crud_module from '../../libs/crud_module'
import EditMember from '@/views/starts/EditMember'
import ExcelUploader from '../../global-components/ExcelUploader'
import StartMembersResults from './StartMembersResults'
import StartStatistic from './StartStatistic'
import MainForm from '@/views/starts/new-version/MainForm'
import MemberResults from '@/views/starts/chip-sync/ChipSync.vue'
import ChipStatistic from '@/views/starts/chip-sync/ChipSync.vue'
import ChipSync from '@/views/starts/chip-sync/ChipSync.vue'

const start_module = crud_module({
  entity_name: 'start',
})
const member_module = crud_module({
  entity_name: 'member-start',
})
const kind_of_sports = crud_module({
  entity_name: 'kind-of-sport',
})
const seasons = crud_module({
  entity_name: 'season',
})
const cities = crud_module({
  entity_name: 'city',
})

export default {
  name: 'StartPage',
  components: {
    ChipSync,
    ChipStatistic,
    MemberResults,
    MainForm,
    StartStatistic,
    StartMembersResults,
    ExcelUploader,
    EditMember,
    FileUploader,
    BTable,
    BBadge,
    quillEditor,
    StartDays,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    BCardText,
    ToastificationContent,
    flatPickr,
    BFormSelect,
    vSelect,
  },

  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      debounce: null,
      step: 1,
      excelResult: null,
      discount: [],
      promocodes: [],
      currentMemberId: '',
      selectedMembers: [],
      age_counting: null,
      kindOfSport: null,
      kindOfSports: [],
      kindOfSports_local: [],
      seasons: [],
      cities: [],
      excelResultColumns: [],
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },

      sponsors: [],
      runs: [],
      age_groups: {
        male: [
          {
            age_from: '',
            age_to: '',
          },
        ],
        female: [
          {
            age_from: '',
            age_to: '',
          },
        ],
      },
      fields: [
        'id',
        {
          key: 'name',
          label: 'Имя',
        },
        {
          key: 'surname',
          label: 'Фамилия',
        },
        {
          key: 'distance',
          label: 'Дистанция',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'Телефон',
        },
        {
          key: 'gender',
          label: 'Пол',
          sortable: true,
        },
        {
          key: 'team',
          label: 'Команда',
          sortable: true,
        },
        {
          key: 'group',
          label: 'Группа',
        },
        {
          key: 'createdAt',
          label: 'Дата регистрации',
        },
        {
          key: 'price',
          label: 'Оплата',
          sortable: true,
        },
        {
          key: 'promocode',
          label: 'Промо',
        },
        {
          key: 'success_payment_reason',
          label: 'Доп инфо',
        },
        {
          key: 'payment',
          label: 'Статус',
          sortable: true,
        },
      ],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      select_kinds_sport: [],
      active_day: 0,
      isloading: false,
      downloadDisabled: false,
      start: {
        city_id: null,
        season_id: null,
        isRescheduled: false,
        isClosed: false,
      },
      organizers: [],
      useful_links: [],
      socials: [],
      sortBy: 'distance',
      sortDesc: false,
      searchMember: '',
    }
  },
  watch: {
    runs: {
      handler(val, oldVal) {
        localStorage.setItem('runs', JSON.stringify(this.runs))
      },
      deep: true,
    },
  },
  async created() {
    await this.getSeasons()
    await this.getCities()
    await this.getKindOfSports()
    if (this.$route.params.id === 'new') {
      this.start.city_id = 639
      this.start.season_id = this.seasons.filter(e => e.is_primary)
        && this.seasons.filter(e => e.is_primary)[0].id
      return
    }
    try {
      this.start = await start_module
        .fetchItem(`admin/${this.$route.params.id}`)
        .then(r => r.data.start_data)
      if (this.start.select_kinds_sport) {
        this.select_kinds_sport = JSON.parse(this.start.select_kinds_sport)
      }

      if (this.start.sponsors.length > 0) {
        this.start.sponsors.forEach(sponsor => {
          if (sponsor.logo) {
            sponsor.logo = JSON.parse(sponsor.logo)
          }
        })

        this.sponsors = this.start.sponsors
      }
      if (this.start.age_counting) {
        this.age_counting = this.start.age_counting
      }
      if (this.start.social_networks && this.start.social_networks.length > 0) {
        this.socials = this.start.social_networks
      }
      if (this.start.discount && this.start.discount.length > 0) {
        this.discount = this.start.discount
      }
      if (this.start.promocodes && this.start.promocodes.length > 0) {
        this.promocodes = this.start.promocodes
      }
      if (this.start.useful_links && this.start.useful_links.length > 0) {
        this.useful_links = this.start.useful_links
      }
      if (this.start.kindOfSports && this.start.kindOfSports.length > 0) {
        this.kindOfSports_local = this.start.kindOfSports.map(e => e.id)
      }
      if (this.start.organizers && this.start.organizers.length > 0) {
        this.start.organizers.forEach(org => {
          if (org.messengers) {
            org.messengers = org.messengers.split(',')
          } else {
            org.messengers = []
          }
        })
        this.organizers = this.start.organizers
      }

      if (this.start.result_columns_json) {
        this.excelResultColumns = JSON.parse(this.start.result_columns_json)
      }

      this.getMembersFromStart()
    } catch (e) {
      console.log(e)
    }
  },
  mounted() {
    if (localStorage.runs) {
      this.runs = JSON.parse(localStorage.getItem('runs'))
    }
  },
  methods: {
    exportStart() {
      console.log(this._data)
    },
    deleteRun(index) {
      this.runs.splice(index, 1)
      localStorage.setItem('runs', JSON.stringify(this.runs))
    },
    addRun() {
      this.runs.push({ name: '', time: '' })
      localStorage.setItem('runs', JSON.stringify(this.runs))
    },
    async getKindOfSports() {
      const data = await kind_of_sports.fetchItems()
      this.kindOfSports = data.data && data.data.rows
    },
    async getSeasons() {
      const data = await seasons.fetchItems()
      this.seasons = data.data && data.data.rows
    },
    async getCities() {
      const data = await cities.fetchItems()
      this.cities = data.data && data.data.rows
    },
    async successResultUpload(data) {
      const result = await this.$http
        .post('/member-result/bulk', {
          start_id: this.$route.params.id,
          results: data.results.map(e => {
            let circles = []
            for (const key in e) {
              if (key.indexOf('circle') === 0) {
                circles.push(e[key])
              }
            }
            circles = JSON.stringify(circles)
            return {
              ...e,
              circles,
              start_id: this.$route.params.id,
            }
          }),
        })
        .then(e => e.data)
      this.$refs.fileResultsComponent.refreshResults()
    },
    async getMembersFromStart() {
      const data = await this.$http
        .get(`/member-start?start_id=${this.$route.params.id}`)
        .then(e => e.data)
      this.$set(this.start, 'members', data)
    },
    async refreshPaymentLink(member) {
      if (!member.price) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            icon: 'BellIcon',
            text: 'Необходимо установить цену участнику, чтобы сформировать ссылку на оплату',
            variant: 'warning',
          },
        })
        return false
      }
      if (!member.hasOwnProperty('disabled')) {
        this.$set(member, 'disabled')
      }
      member.disabled = true
      const result = await this.$http
        .put(`/member-start/${member.id}/repayment`)
        .then(r => r.data)
      if (result.payment.formUrl) {
        const data = [
          new ClipboardItem({
            'text/plain': new Blob([result.payment.formUrl], {
              type: 'text/plain',
            }),
          }),
        ]
        navigator.clipboard.write(data).then(
          () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Скопировано',
                icon: 'BellIcon',
                text: 'Новая ссылка на оплату скопирована в буфер обмена',
                variant: 'success',
              },
            })
          },
          () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка',
                icon: 'BellIcon',
                text: 'Невозможно поместить ссылку в буфер обмена',
                variant: 'warning',
              },
            })
            alert(result.payment.formUrl)
          },
        )
      }
      member.disabled = false
    },
    addResultLink() {
      this.start.results.push({
        name: '',
        file: null,
      })
    },
    async changeStatus(status) {
      if (this.selectedMembers.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'BellIcon',
            text: 'Не выбраны участники',
            variant: 'warning',
          },
        })
        return false
      }
      const result = await this.$http.post('/member-start/payment', {
        ids: this.selectedMembers,
        payment: status,
      })
      if (!result) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'BellIcon',
            text: 'Ошибка смены статуса оплаты',
            variant: 'warning',
          },
        })
        return false
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Уведомление',
          icon: 'BellIcon',
          text: 'Смена статуса оплаты прошла успешно',
          variant: 'success',
        },
      })
      this.getMembersFromStart()
      this.selectedMembers = []
    },
    async cancelPayments() {
      if (this.selectedMembers.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'BellIcon',
            text: 'Не выбраны участники',
            variant: 'warning',
          },
        })
        return false
      }
      const result = await this.$http.post('/member-start/payment', {
        ids: this.selectedMembers,
        payment: false,
      })
      if (!result) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'BellIcon',
            text: 'Ошибка отмены оплаты',
            variant: 'warning',
          },
        })
        return false
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Уведомление',
          icon: 'BellIcon',
          text: 'Отмена оплаты прошла успешно',
          variant: 'success',
        },
      })
      this.getMembersFromStart()
      this.selectedMembers = []
    },
    editMember(id) {
      this.currentMemberId = id
    },
    deleteMemberModal(id) {
      this.currentMemberId = id
    },

    async deleteMember(id) {
      await member_module.deleteItem(id)
      await this.getMembersFromStart()
      await this.$refs['delete-modal'].hide()
    },

    downloadExcel() {
      if (!this.start.members || this.start.members.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Нет участников',
            icon: 'BellIcon',
            text: 'Нет зарегистрированных участников на старт для скачивания файла',
            variant: 'warning',
          },
        })
        return false
      }
      this.downloadDisabled = true

      const data = [
        {
          sheet: 'Участники',
          columns: [
            { label: 'id', value: 'id' },
            { label: 'Имя', value: 'name' },
            { label: 'Фамилия', value: 'surname' },
            { label: 'Дистанция', value: 'distance' },
            { label: 'Телефон', value: 'phone' },
            { label: 'Почта', value: 'email' },
            { label: 'Пол', value: 'gender' },
            { label: 'Команда', value: 'team' },
            {
              label: 'Дата рождения',
              value: row => this.$moment(row.birthday).format('DD.MM.YYYY'),
            },
            {
              label: 'Дата регистрации',
              value: row => this.$moment(row.createdAt).format('DD.MM.YYYY'),
            },
            {
              label: 'Статус',
              value: row => (row.payment ? 'Оплачено' : 'Не оплачено'),
            },
            { label: 'Оплата', value: 'price' },
            {
              label: 'Группа',
              value: row => `${JSON.parse(row.group).ageFrom}-${
                JSON.parse(row.group).ageTo
              }_${JSON.parse(row.group).name}`,
            },
            { label: 'Промо', value: 'promocode' },
            { label: 'Доп инфо', value: 'success_payment_reason' },
          ],
          content: this.start.members,
        },
      ]

      const settings = {
        fileName: `${this.start.name} - Участники`,
        extraLength: 3,
        writeOptions: {},
      }

      xlsx(data, settings)

      this.downloadDisabled = false
    },
    saveItem() {
      if (this.age_counting === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Николай, заполните, пожалуйста это поле, если вас не затруднит',
            variant: 'danger',
          },
        })
        return false
      }
      this.start.age_counting = this.age_counting
      this.start.sponsors = []
      this.sponsors.forEach(sponsor => {
        if (sponsor.logo) {
          this.start.sponsors.push({
            ...sponsor,
            logo: JSON.stringify(sponsor.logo),
          })
        }
      })

      this.start.useful_links = this.useful_links
      this.start.social_networks = this.socials
      this.start.discount = this.discount
      this.start.promocodes = this.promocodes
      this.start.kindOfSports = this.kindOfSports_local

      console.log(this.start)

      this.organizers.forEach(org => {
        if (org.messengers) {
          org.messengers = org.messengers.join(',')
        }
      })

      this.start.organizers = this.organizers

      if (this.excelResultColumns.length > 0) {
        this.start.result_columns_json = JSON.stringify(this.excelResultColumns)
      } else {
        this.start.result_columns_json = null
      }

      if (this.$route.params.id === 'new') {
        this.createStart()
      } else {
        this.updateStart()
      }
    },
    async updateStart() {
      this.isloading = true
      try {
        this.start.select_kinds_sport = JSON.stringify(this.select_kinds_sport)
        const res = await start_module
          .updateItem(this.start.id, this.start)
          .then(r => r.data)
        this.isloading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Старт сохранен',
            variant: 'success',
          },
        })
        /* this.$router.push({ name: 'starts' }) */
      } catch (e) {
        this.isloading = false
        console.error(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'BellIcon',
            text: e.response.data.message,
            variant: 'warning',
          },
        })
      }
    },
    async createStart() {
      this.isloading = true
      try {
        this.start.select_kinds_sport = JSON.stringify(this.select_kinds_sport)
        const res = await start_module.createItem(this.start).then(r => r.data)
        this.isloading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Старт добавлен',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'starts' })
      } catch (e) {
        this.isloading = false
        console.error(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'BellIcon',
            text: e.response.data.message,
            variant: 'danger',
          },
        })
      }
    },
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      if (search.length >= 0) {
        this.debounce = setTimeout(fn, 600)
      }
    },
    getFilteredMembers(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          const data = await this.$http
            .get(
              `/member-start?start_id=${this.$route.params.id}&filterText=${search}`,
            )
            .then(e => e.data)
          this.$set(this.start, 'members', data)
        } catch (e) {
          console.error(e)
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.nav-bar {
  display: flex;
  align-items: center;
  margin: 0 -5px 10px -5px;

  &__item {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 20px;
    border-radius: 5px;

    margin: 0 5px;
    color: #636363;
    transition: 0.3s;
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
    .dark-layout & {
      color: #ffffff;
      &.active {
        color: #ffffff;
      }
    }
    &.active {
      background: #7367f0;
      color: #ffffff;
    }
  }
}

@import '@core/scss/vue/libs/vue-flatpicker.scss';
.upload-wrap {
  margin-bottom: 20px;
  // width: 40px;
  // height: 40px;
}
.custom_input {
  display: flex;
  width: 100%;
  input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .input-group-text {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
.time-pick {
  margin-right: 15px;
}
.save-button {
  position: fixed !important;
  bottom: 30px;
  right: 15px;
  z-index: 1000;
}
.vs-button-day {
  margin: 5px 0;
  background: #7367f0 !important;
  border: 1px solid #7367f0;
  color: white;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}
.tabs-day-block {
  margin-top: 20px;
}
.fade-tab-enter-active,
.fade-tab-leave-active {
  transition: all 0.3s;
}

.fade-tab-enter, .fade-tab-leave-to /* .fade-tab-leave-active below version 2.1.8 */ {
  top: 0;
  opacity: 0;
  position: absolute !important;
  transform: translate3d(-100%, 0, 0);
}

.fade-tab-leave-to /* .fade-tab-leave-active below version 2.1.8 */ {
  top: 0;
  opacity: 0;
  position: absolute !important;
  transform: translate3d(100%, 0, 0);
}

.switches {
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.switch {
  margin-right: 10px;
  &:first-child {
    @media (max-width: 767px) {
      margin-top: 16px;
    }
  }
}

.img-uploader {
  margin: 10px 0;
}

.links {
  display: flex;
  align-items: center;

  svg {
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      transform: scale(1.3);
    }
  }

  &__input {
    margin: 10px 0;

    &:nth-child(2) {
      margin: 10px 10px;
    }
    &:last-child {
    }
  }
}

.organizer {
  &__wrap {
    display: flex;

    input {
      margin: 0 10px;
    }
  }

  &__switch {
    margin: 0 10px;
  }
}
.flex-wrapper {
  display: flex;
}
.promo {
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &__item {
    position: relative;
    margin: 0 15px 10px 15px;
    display: flex;
    flex-direction: column;
    max-width: max-content;
    box-shadow: 0px 0px 23px -2px rgba(34, 60, 80, 0.2);
    border-radius: 20px;
    padding: 25px;
    &-del {
      position: absolute;
      top: 10px;
      right: 10px;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}
.discount {
  &__items {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    position: relative;
    margin: 0 15px 10px 15px;
    display: flex;
    flex-direction: column;
    max-width: max-content;
    box-shadow: 0px 0px 23px -2px rgba(34, 60, 80, 0.2);
    border-radius: 20px;
    padding: 25px;
    &-del {
      position: absolute;
      right: 9px;
      top: 5px;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}

.search-wrap {
  display: flex;
  justify-content: right;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
