<template>
  <div>
    <b-modal
      v-if="sport.type === 'combo'"
      :id="'bv-modal-edit' + index"
      class="z-index"
      title="Редактировать комбо дистанций"
      ok-only
      ok-title="Сохранить"
      @ok="updateSport"
    >
      <b-form-input
        v-model="new_kind_sport"
        placeholder="Название"
        label="Название"
        vs-placeholder="Название"
        class="w-full"
      />

      <b-form-input
        v-model="sport.sale"
        placeholder="Скидка"
        vs-placeholder="Скидка"
        class="inline-block mt-2"
      />

      <b-form-checkbox-group 
        v-for="(distance, index) in filteredDistances" 
        :key="index"
        v-model="selectedDistances"
      >
        <b-form-checkbox
          class="mt-1 mr-1"
          :value="distance.value"
          :key="index"
        > 
          {{ distance.value }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-modal>
    <b-modal v-else
      :id="'bv-modal-edit' + index"
      class="z-index"
      title="Редактировать дистанцию"
      ok-only
      ok-title="Сохранить"
      @ok="updateSport"
    >
      <div class="con-example-prompt">
        <b-form-input
          v-model="new_kind_sport"
          placeholder="Новая дистанция"
          label="Новая дистанция"
          vs-placeholder="Новая дистанция"
          class="w-full"
        />

        <div class="mt-3 mb-3 title-format">
          Формат
        </div>
        <div
          v-for="(format, index) in sport_formats"
          :key="'format' + index"
          class="mb-1"
        >
          <b-form-radio
            v-model="activeFormat"
            name="sport_format"
            class="mr-6"
            :value="format"
          >
            {{ format }}
          </b-form-radio>
        </div>
      </div>

      <template v-if="activeFormat === 'Лично'">
        <div>
          <distance-block :distance="distance" :mode="'Лично'"></distance-block>
          <div class="mt-3 mb-3 mt-3">
            Группы ({{ groups.length }})
          </div>
          <group-templates @select="(group) => {groups = group}" />
            <template v-for="(group, index) in groups">
              <group 
                :groups="groups"
                :group="group"
                :key="'group' + index"
                :group-index="index"
                @update-group="updateGroup"
                @delete-group="deleteGroup"
              >
              </group>
            </template>
          <b-button
            class="mt-3"
            size="small"
            @click="addGroup"
          >
            Добавить группу
          </b-button>
        </div>
      </template>

      <template v-if="activeFormat === 'Эстафета'">
        <distance-block :distance="distance"></distance-block>
        <div class="mt-3 mb-3 mt-3">
          Этапы ({{ distanceStages.length }})
        </div>
        <stage-block :stages="distanceStages"></stage-block>
        <b-button
          class="mt-3"
          size="small"
          @click="addStage"
        >
          Добавить этап
        </b-button>

        <div class="mt-3 mb-3 mt-3">
          Группы ({{ groups.length }})
        </div>
        <group-templates @select="(group) => {groups = group}" />
        <template v-for="(group, index) in groups">
          <group 
            :groups="groups"
            :group="group"
            :key="'group' + index"
            :group-index="index"
            @update-group="updateGroup"
            @delete-group="deleteGroup"
          >
          </group>
        </template>

        <b-button
          class="mt-3"
          size="small"
          @click="addGroup"
        >
          Добавить группу
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormCheckbox, BButton, BModal, BFormRadio, BFormCheckboxGroup
} from 'bootstrap-vue'
import GroupTemplates from '@/views/starts/GroupTemplates'
import DistanceBlock from './DistanceBlock.vue'
import Group from './Group.vue'
import StageBlock from './StageBlock.vue'
  
export default {
  name: 'edit-distance',
  components: {
    StageBlock,
    Group,
    DistanceBlock,
    GroupTemplates,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    BFormRadio,
    BFormCheckboxGroup
  },
  props: ['age_groups', 'select_kinds_sport', 'sport', 'index'],
  data() {
    return {
      activeFormat: '',
      distance: {},
      groups: [],
      distanceStages: [],
      slotDisable: false,
      kind_sport: '',
      activeSelectSport: false,
      new_kind_sport: '',
      sport_formats: [
        'Лично',
        'Эстафета',
      ],
      selectedDistances: []
    }
  },
  computed: {
    filteredDistances() {
      return this.select_kinds_sport.filter(item => item?.type !== 'combo')
    },
  },
  created() {
    console.log('sport', JSON.parse(JSON.stringify(this.sport)));
    this.new_kind_sport = this.sport.value
    this.groups = this.sport.groups
    this.activeFormat = this.sport.format
    this.distanceStages = this.sport.distanceStages
    this.distance = this.sport.distance
    this.transformSelectedDistances()
  },
  mounted() {
  },
  methods: {
    addStage() {
      this.distanceStages.push(
        {
          value: '',
          sex: []
        }
      )
    },
    addGroup() {
      if (this.activeFormat === 'Лично') {
          this.groups.push({
            name: '',
            ageFrom: '',
            ageTo: '',
            sex: ''
          })
      } else if (this.activeFormat === 'Эстафета') {
        this.groups.push({
          name: '',
          ageFrom: '',
          ageTo: '',
          stages: [...this.distanceStages],
        })
      }
    },
    updateSport() {
      if (this.new_kind_sport) {
        if (this.sport.type === 'combo') {
          const distancesData = []
          this.selectedDistances.forEach(item => {
            this.select_kinds_sport.forEach(distance => {
              if (item === distance.value){
                distancesData.push(distance)
              }
              return
            })
          });

          this.select_kinds_sport[this.index] = {
            value: this.new_kind_sport,
            type: this.sport.type,
            distances: distancesData,
            sale: this.sport.sale
          }
        } else {
          this.select_kinds_sport[this.index] = {
            value: this.new_kind_sport,
            prompt: false,
            groups: this.groups,
            format: this.activeFormat,
            distanceStages: this.distanceStages,
            distance: this.distance
          }
        }
      }
    },
    updateGroup(group, groupIndex) {
      this.groups[groupIndex] = group
    },
    transformSelectedDistances() {
      if (this.sport.distances) {
        this.selectedDistances = this.sport?.distances.map(distance => distance.value)
      }
    },
    addPrices(distance) {
      if (!distance.prices) {
        this.$set(distance, 'prices', [])
      }
      distance.prices.push({ c_to: '', c_from: '', value: '' })
    },
    deleteGroup(indexGroup) {
      this.groups.splice(indexGroup, 1)
    }
  },
}
</script>

<style lang="scss" scoped>

.add-fille{
  margin: 10px 0;
}
.distance{
  margin-top: 30px;
}
.title-format{
  margin: 15px 0!important;
}
.input__code{
  margin-top: 15px!important;
}
.con-example-prompt{
  input{
    margin-top: 20px!important;
  }
}
.distances__minus{
  margin-left: 5px;
  transition: .3s;
  &:hover{
    cursor: pointer;
    transform: scale(1.1);
  }
}
    .sport-block {
        border-bottom: 1px solid #e5e5e5;
    }

    .z-index {
        z-index: 52011;
    }

.price{
  &__title{
    margin-top: 20px;
  }
  &__items{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 20px -15px;
  }
  &__item{
    padding: 20px;
    border: 1px solid #00000012;
    border-radius: 20px;
    position: relative;
    width: calc(50% - 30px);
    margin: 10px 15px 0 15px;

    &-del{
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

}

</style>
<style>
.flex{
  display: flex;

}
.justify-end{

  justify-content: end;
}
.inline-block{
  display: inline-block;
}
.age{
  display: flex;

}
.age input:last-child{
  margin-left: 30px;
}
.price__item .form-control{
  margin-top: 20px;
}
</style>
